<template>
  <div>
    <material-card
      color="general"
      :title="$t('Order.title')"
      :text="$t('Order.subtitle')"
    >
      <!-- Set location -->
      <v-dialog
        v-if="dialogSetLocation"
        v-model="dialogSetLocation"
        max-width="500px"
      >
        <v-card>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-btn
                  color="blue"
                  @click="changeGetAddressModalVisiblity(true)"
                >
                  {{ $t('Order.select_coordinates_on_map') }}
                </v-btn>
              </v-layout>
              <v-layout wrap>
                <v-flex>
                  <v-text-field
                    v-model="editedItemSetLocation.lat"
                    :rules="[rules.required, rules.latitude]"
                    :label="$t('Order.latitude')"
                  />
                  <v-text-field
                    v-model="editedItemSetLocation.lon"
                    :rules="[rules.longitude]"
                    :label="$t('Order.longitude')"
                  />
                </v-flex>
              </v-layout>
            </v-container>
            <v-alert
              v-if="alertTextLocation"
              dense
              type="error"
            >
              {{ alertTextLocation }}
            </v-alert>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="zwonogreen"
              :loading="isSavingSetLocation"
              @click="saveSetLocation"
            >
              {{ $t("Common.save") }}
            </v-btn>
            <v-btn
              color="zwonogray"
              @click="closeSetLocation"
            >
              {{ $t("Common.cancel") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-text-field
          v-model="paginationOptions.search"
          append-icon="mdi-magnify"
          :label="$t('Common.search')"
          single-line
          hide-details
          solo
          class="z-search mb-4"
        />
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="orderList ? orderList : []"
          :options.sync="paginationOptions"
          :server-items-length="(orderListMeta?.itemCount) ? orderListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <template #top>
            <v-switch
              v-model="paginationOptions.filters['customer.is_not_geocoded']"
              :label="$t('Order.only_not_geocoded')"
              class="z-order-switch px-4 pt-3 mt-0"
            />
          </template>
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.order_date`]="{ item }">
            {{ formatDate(item.order_date) }}
          </template>
          <template #[`item.customer.is_geocoded`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <td v-if="item.customer.is_geocoded === false">
                  <v-icon
                    color="red"
                    v-bind="attrs"
                    @click="editItemSetLocation(item)"
                    v-on="on"
                  >
                    mdi-alert-octagon
                  </v-icon>
                </td>
                <td v-else>
                  <v-icon
                    color="green"
                    v-bind="attrs"
                    @click="editItemSetLocation(item)"
                    v-on="on"
                  >
                    mdi-check-all
                  </v-icon>
                </td>
              </template>
              <span>{{ $t("Common.tooltips.location") }}</span>
            </v-tooltip>
          </template>
          <template #[`item.status`]="{ item }">
            <td>
              <div>
                <div class="d-inline-block status-max-w">
                  <!-- REMOVE CHANGING STATUS <v-select
                    v-model="item.status"
                    :items="orderStatusItems"
                    item-value="value"
                    item-text="label"
                    class="pt-0 mt-0"
                    density="compact"
                    hide-details
                    @input="changeOrderStatus(item)"
                  />-->
                  {{ item.status }}
                </div>
                <div class="d-inline-block">
                  <v-tooltip
                    v-if="item.has_changed_items"
                    bottom
                  >
                    <template #activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        color="red"
                        v-on="on"
                      >
                        mdi-cart
                      </v-icon>
                    </template>
                    <span>{{ $t("Common.tooltips.changed-items") }}</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="mr-2"
                  color="general"
                  v-bind="attrs"
                  @click="openItemsModal(item)"
                  v-on="on"
                >
                  apps
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.details") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color mr-2"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.delete") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  :class="
                    item.alternative_address
                      ? 'z-hover-orange-color'
                      : 'z-hover-orange-color'
                  "
                  :color="item.alternative_address ? 'orange' : ''"
                  v-bind="attrs"
                  @click="openAlternateAddressModal(item)"
                  v-on="on"
                >
                  mdi-google-maps
                </v-icon>
              </template>
              <span>{{ $t("Common.tooltips.edit-alternative-address") }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              <v-icon left>
                mdi-refresh
              </v-icon>
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <v-dialog
        v-if="isItemsModalVisible"
        v-model="isItemsModalVisible"
        max-width="1000px"
      >
        <!-- <template #activator="{ on }">
          <v-btn
            color="general"
            dark
            class="mb-2"
            v-on="on"
          >
            New Item
          </v-btn>
        </template> -->

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-bookmark-outline
            </v-icon>
            {{ $t("Delivery.ordered_items") }}
            <v-spacer />
            <v-btn
              icon
              @click="closeOrderItems"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <div
            v-if="spinnerOrderItemActive"
            class="table-spinner-container"
          >
            <v-progress-circular
              color="primary"
              indeterminate
              :size="100"
              :width="8"
            />
          </div>
          <div v-else>
            <v-card-text>
              <v-form v-model="orderDetailsValid">
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex>
                      <v-text-field
                        v-if="orderDetails.customer"
                        v-model="orderDetails.customer.customer_name"
                        :label="$t('Common.customer_name')"
                        :disabled="!editing"
                      />
                      <v-text-field
                        v-if="orderDetails.customer"
                        v-model="orderDetails.full_address"
                        :label="$t('Common.address')"
                        :disabled="true"
                      />
                      <v-text-field
                        v-if="orderDetails.customer && orderDetails.alternative_address"
                        v-model="orderDetails.full_alternative_address"
                        :label="$t('Common.alternative_address')"
                        :disabled="true"
                      />
                      <v-text-field
                        v-if="orderDetails.customer && orderDetails.alternative_address"
                        :label="$t('Common.is_user_change')"
                        :value="orderDetails.alternative_address?.is_user_change ? $t('Common.yes') : $t('Common.no')"
                        :disabled="true"
                      />
                      <v-text-field
                        v-if="orderDetails.customer"
                        v-model="orderDetails.customer.phone_number"
                        :label="$t('Common.phone_number')"
                        :disabled="!editing"
                      />
                      <v-text-field
                        v-if="orderDetails.customer"
                        v-model="orderDetails.customer.email"
                        :label="$t('Common.email')"
                        :disabled="!editing"
                      />
                      <v-text-field
                        v-model="orderDetails.order_date_formatted"
                        :label="$t('Order.order_date')"
                        :disabled="true"
                      />
                      <v-text-field
                        v-model="orderDetails.description"
                        :label="$t('Common.description')"
                        :disabled="!editing"
                      />
                      <v-text-field
                        v-model="orderDetails.note"
                        :label="$t('Common.note')"
                        :disabled="!editing"
                      />
                      <v-text-field
                        v-model="orderDetails.price"
                        :rules="[rules.required]"
                        :label="$t('Common.price')"
                        :disabled="!editing"
                        type="number"
                      />
                      <v-select
                        v-model="orderDetails.endclient_status"
                        :items="endclientStatusItems"
                        item-value="value"
                        item-text="label"
                        density="compact"
                        :disabled="!editing"
                        :label="$t('Common.endclient_status')"
                        hide-details
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-data-table
                  :loading-text="$t('Common.loading-text')"
                  :headers="headersItems"
                  :items="orderDetails.items"
                  class="elevation-1"
                >
                  <template slot="body.append">
                    <tr>
                      <th>{{ $t("Common.total") }}</th>
                      <th />
                      <th />
                      <th>{{ orderDetails.price }}</th>
                    </tr>
                  </template>
                  <template #[`item.total`]="{ item }">
                    {{ item.qty * item.price }}
                  </template>
                </v-data-table>
              </v-form>
            </v-card-text>
            <div
              ref="map-root"
              style="width: 100%; height: 100%"
            >
              <p />
            </div>

            <v-card-actions class="d-flex justify-center">
              <v-btn
                v-if="!editing"
                color="zwonoyellowdark"
                class="pr-5"
                @click="editOrder"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                {{ $t("Common.tooltips.edit") }}
              </v-btn>
              <v-btn
                v-if="editing"
                color="zwonogray"
                class="z-hover-red-bg-color pr-5"
                @click="closeOrderItems"
              >
                <v-icon left>
                  mdi-cancel
                </v-icon>
                {{ $t("Common.cancel") }}
              </v-btn>
              <v-btn
                v-if="!editing"
                color="zwonogreen"
                class="pr-5"
                @click="closeOrderItems"
              >
                <v-icon left>
                  mdi-check
                </v-icon>
                {{ $t("Common.ok") }}
              </v-btn>
              <v-btn
                v-if="editing"
                color="zwonogreen"
                class="pr-5"
                :disabled="!orderDetailsValid"
                @click="updateOrderDetails(orderDetails)"
              >
                <v-icon left>
                  mdi-check
                </v-icon>
                {{ $t("Common.save") }}
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-dialog>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="(value) => (isDeleteModalVisible = value)"
      @confirm-btn-click="() => deleteItem()"
    />
    <change-deal-alternative-address-modal 
      :is-visible="isAlternativeAddressModalVisible"
      :order-id="alternativeAddressModalOrderId"
      :customer-name="alternativeAddressModalCustomerName"
      @is-visible="(value) => changeAlternateAddressVisibility(value)"
      @is-changed="(item) => markChanged(item)"
    />
    <get-address-modal
      :is-visible="isGetAddressModalVisible"
      :is-saving="false"
      :title="$t('Order.select_coordinates_on_map') + ': ' + (orderDetails?.customer?.address1 + (orderDetails?.customer?.house_number ? ` ${orderDetails?.customer?.house_number}`: '') + (orderDetails?.customer?.city ? `, ${orderDetails?.customer?.city}`: '') + (orderDetails?.customer?.country ? `, ${orderDetails?.customer?.country}`: ''))"
      :prefill="orderDetails?.customer?.address1 + (orderDetails?.customer?.house_number ? ` ${orderDetails?.customer?.house_number}`: '')"
      @is-visible="(value) => changeGetAddressModalVisiblity(value)"
      @address-object="(item) => changeAddressObject(item)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ChangeDealAlternativeAddressModal from "../../components/modals/ChangeDealAlternativeAddressModal.vue"
import GetAddressModal from "../../components/modals/GetAddressModal.vue";
import { mapFullAlternativeAddress } from "../../helpers/helpers.js"
import DefaultPopup from "../../components/DefaultPopup.vue";

// importing the OpenLayers stylesheet is required for having
// good looking buttons!
import "ol/ol.css";

export default {
  components: { 
    ChangeDealAlternativeAddressModal,
    GetAddressModal,
    DefaultPopup
  },
  data: () => ({
    isItemsModalVisible: false,
    isDeleteModalVisible: false,
    isGetAddressModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      order_date: null,
      description: null,
      price: 0,
      customer_id: "",
      note: "",
    },
    defaultItem: {
      order_date: null,
      description: null,
      price: 0,
      customer_id: "",
      note: "",
    },
    menuDatePicker: false,
    orderDetails: {},
    orderDetailsValid: true,
    dialogSetLocation: false,
    editedSetLocationIndex: -1,
    editedItemSetLocation: {
      lat: "",
      lon: "",
      customer_id: "",
    },
    defaultItemSetLocation: {
      lat: "",
      lon: "",
      customer_id: "",
    },
    isAlternativeAddressModalVisible: false,
    alternativeAddressModalOrderId: "",
    alternativeAddressModalCustomerName: "",
    alternativeAddress: {},
    alertTextDelete: "",
    alertTextEditAddress: "",
    alertTextLocation: "",
    spinnerOrderItemActive: false,
    refreshKey: 0,
    isSavingSetLocation: false,
    editing: false,
    // table:
    paginationLoading: false,
    paginationOptions: { filters:{} },
    oldSearchValue: '',
  }),

  computed: {
    ...mapGetters("order", ["orderList", "orderListMeta", "order", "orderStatusItems", "endclientStatusItems"]),

    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    headers() {
      return [
        {
          text: this.$t("Order.order_date"),
          value: "order_date",
          filterable: false,
        },
        {
          text: this.$t("Common.description"),
          value: "description",
          filterable: false,
        },
        { text: this.$t("Common.price"), value: "price", filterable: false },
        {
          text: this.$t("Common.customer_name"),
          value: "customer.customer_name",
        },
        { text: this.$t("Common.status"), value: "status", filterable: false },
        { text: this.$t("Common.endclient_status"), value: "endclient_status", filterable: false },
        {
          text: this.$t("Common.actions"),
          value: "actions",
          filterable: false,
          sortable: false
        },
        {
          text: this.$t("Order.geo"),
          value: "customer.is_geocoded",
          filterable: false,
        },
      ];
    },
    headersItems() {
      return [
        { text: this.$t("Product.product_name"), value: "product_name" },
        { text: this.$t("Common.qty"), value: "qty" },
        { text: this.$t("Common.price"), value: "price" },
        { text: this.$t("Common.total"), value: "total" },
      ];
    },
    rules() {
      return {
        required: (value) => !!value || this.$t("Common.validation_required"),
      };
    },
  },

  watch: {
    order(val) {
      this.orderDetails = val;
      if (this.orderDetails) {
        this.orderDetails.full_address =
          this.orderDetails.customer.country +
          ", " +
          this.orderDetails.customer.city +
          ", ";
        if (
          this.orderDetails.customer.city_details &&
          this.orderDetails.customer.city_details !== null
        ) {
          this.orderDetails.full_address +=
            this.orderDetails.customer.city_details + ", ";
        }
        this.orderDetails.full_address +=
          this.orderDetails.customer.address1 + " ";
        this.orderDetails.full_address += [
          this.orderDetails.customer.house_number,
          this.orderDetails.customer.floor_details,
          this.orderDetails.customer.appartment_details,
        ]
          .filter(Boolean)
          .join(" / ");
        this.orderDetails.order_date_formatted = this.formatDate(
          this.orderDetails.order_date
        );

        this.orderDetails.full_alternative_address = mapFullAlternativeAddress(this.orderDetails.alternative_address)
      }
    },
    paginationOptions: {
      handler(val) {
        if(val.search !== this.oldSearchValue) this.paginationOptions.page = 1
        this.oldSearchValue = val.search;
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
  },

  //lifecycle hooks
  beforeDestroy() {
    this.clearOrderState();
    this.clearCustomerState();
  },

  methods: {
    ...mapActions("order", [
      "getOrderList",
      "deleteOrder",
      "updateOrder",
      "getOrder",
      "clearOrderState",
    ]),
    ...mapActions("customer", [
      "updateCustomerLocation",
      "clearCustomerState",
    ]),
    ...mapMutations("order", ["changeAlternativeAddressInOrderList"]),

    async initialize() {
      this.paginationLoading = true;
      this.role = sessionStorage.getItem("role");
      this.paginationOptions.role = this.role;
      if(this.paginationOptions.filters['customer.is_not_geocoded']) {
        this.paginationOptions.filters['customer.is_geocoded'] = !this.paginationOptions.filters['customer.is_not_geocoded']
        delete this.paginationOptions.filters['customer.is_not_geocoded']
      } else {
        delete this.paginationOptions.filters['customer.is_geocoded']
      }
      await this.getOrderList(this.paginationOptions);
      this.paginationLoading = false;
    },

    showDeleteModal(item) {
      this.alertTextDelete = "";
      this.isDeleteModalVisible = true;
      this.deleteModalItemId = item?.id;
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteOrder(this.deleteModalItemId);
        if (isDeleted) {
          this.isDeleteModalVisible = false;
        } else {
          this.alertTextDelete = this.$t("Order.alerts.error-delete");
        }
      }
    },
    async openItemsModal(item) {
      this.spinnerOrderItemActive = true;
      await this.getOrderItems(item)
      this.spinnerOrderItemActive = false;
      this.isItemsModalVisible = true;
    },
    async getOrderItems(item) {
      await this.getOrder(item.id)
    },
    closeOrderItems() {
      this.isItemsModalVisible = false;
      this.orderDetailsValid = true;
      this.editing = false;
    },
    formatDate(value) {
      return new Date(value).toLocaleDateString();
    },
    // Set Location
    closeSetLocation() {
      this.dialogSetLocation = false;
      this.editedItemSetLocation = Object.assign(
        {},
        this.defaultItemSetLocation
      );
      this.editedSetLocationIndex = -1;
    },

    async saveSetLocation() {
      this.isSavingSetLocation = true;
      this.alertTextLocation = "";
      if (this.editedSetLocationIndex > -1) {
        const isUpdated = await this.updateCustomerLocation(
          this.editedItemSetLocation
        );
        if (isUpdated) {
          this.closeSetLocation();
        } else {
          this.alertTextLocation = this.$t("Order.alerts.error-set-location");
        }
      }
      this.closeSetLocation();
      this.isSavingSetLocation = false;
    },

    editItemSetLocation(item) {
      this.getOrderItems(item)
      this.editedSetLocationIndex = this.orderList.indexOf(item);
      if (item.customer) {
        this.editedItemSetLocation.lat = item.customer.lat;
        this.editedItemSetLocation.lon = item.customer.lon;
        this.editedItemSetLocation.customer_id = item.customer.id;
      }
      this.alertTextLocation = "";
      this.dialogSetLocation = true;
    },
    // Alternative Address
    openAlternateAddressModal(value) {
      this.getOrderItems(value)
      this.alternativeAddress = value.alternative_address || {};
      this.alternativeAddressModalOrderId = value.id;
      this.alternativeAddressModalCustomerName = value.customer.customer_name;
      this.isAlternativeAddressModalVisible = true;
    },
    changeAlternateAddressVisibility(isVisible) {
      this.isAlternativeAddressModalVisible = isVisible
    },

    markChanged(item) {
      this.changeAlternativeAddressInOrderList({orderId: item.orderId, alternativeAddress: item.addressObj})
    },

    async changeOrderStatus(item) {
      if (await this.updateOrder({id: item.id, status: item.status})) {
        this.$root.vtoast.show({
          message: this.$t("Order.alerts.status-updated"),
        });
      } else {
        this.$root.vtoast.show({
          message: this.$t("Order.alerts.status-not-updated"),
          color: "error",
        });
      }
    },

    async updateOrderDetails(item) {
      this.editing = false;
      await this.updateOrder(item);
    },

    editOrder() {
      this.editing = true;
    },

    async changeGetAddressModalVisiblity(isVisible) {
      this.isGetAddressModalVisible = isVisible
    },

    changeAddressObject(addressObj) {
      this.editedItemSetLocation.lat = addressObj.lat;
      this.editedItemSetLocation.lon = addressObj.lon;
      
      this.changeGetAddressModalVisiblity(false);
    }
  },
};
</script>
